<template>
    <v-menu bottom left offset-y
        :close-on-content-click="false"
        :nudge-width="200"
    >
        <template v-slot:activator="{ on, attrs }">
            <fa-icon
                icon="filter"
                class="text-black text-xs px-3"
                v-bind="attrs"
                v-on="on"
            />
        </template>
        <div class="p-3 text-xxs bg-white-pure uppercase font-bold text-center">Filtering coming soon...</div>
        <div class="p-3 text-xxs bg-white-pure">
            <div class="uppercase text-grey-2 text-xs font-bold">Tags</div>
            <div class="w-150 mb-4">
                <v-autocomplete
                    :items="computedItems"
                    outlined hide-details
                    dense
                    chips
                    small-chips
                    disabled
                ></v-autocomplete>
            </div>
            <div class="flex items-center">
                <v-btn
                    depressed outlined text
                    disabled
                    :ripple="false"
                    :small="true"
                    color="white"
                    class="border border-grey-4 mr-2"
                >Clear Filters</v-btn>
                <v-btn
                    disabled
                    :small="true"
                    :ripple="false"
                    color="primary"
                    depressed
                >Update</v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
import { computed } from '@vue/composition-api'
export default {
    name: 'PracticeFiltersMenu',
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    setup(props) {
        const computedItems = computed(() => props.items)

        return { computedItems }
    }
}
</script>