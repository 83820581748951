import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')


export function useNameHelpers(variables) {
    const { staffTitles } = useGetters(['staffTitles'])
    
    //#region functions
    const getFullName = (user) => {
        const { title, firstName, lastName } = user
        // return `${surveyor.title || ''} ${surveyor.firstName || ''} ${surveyor.lastName || ''}`.replace(/ /g, '')
        return `${getTitle(title) || ''} ${firstName || ''} ${lastName || ''}`.trim()
    }
    
    const getTitle = (title) => {
        return staffTitles.value.find(st => st.value === title)?.label || ''
    }
    //#endregion
    
    return {
        getFullName,
        getTitle
    }
}
