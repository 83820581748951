<template>
    <header>
        <div
            class="bg-grey-8 w-full py-3 px-4 flex items-center border border-grey-4 border-l-0 border-t-0 border-r-0"
            id="SurveyVisitHeader"
        >
            <div class="flex items-center">
                <div class="uppercase text-xs text-grey-2 font-display font-bold mr-2">Surveys Due in</div>
                <div class="w-150">
                    <DateRangePicker
                        v-model="filter.dateRange"
                        class="mr-4"
                        :max-date="null"
                        :solo="true"
                        :outlined="true"
                        :flat="true"
                    />
                </div>
            </div>
            <!-- TODO: change class with one to gery out text - and remove this comment when it's done-->
            <div class="flex items-center mr-4">
                <div
                    class="uppercase text-xs text-grey-2 font-display font-bold mr-2"
                    :class="{'text-grey-6': filterType !== 'region'}"
                >Region</div>
                <div class="w-150">
                    <v-autocomplete
                        outlined
                        hide-details
                        dense
                        chips
                        small-chips
                        clearable
                        :items="states"
                        v-model="filter.region"
                        @focusin.prevent="setFilterType('region')"
                        @change="filterUpdated"
                    ></v-autocomplete>
                </div>
            </div>
            <div class="flex items-center mr-4">
                <div
                    class="uppercase text-xs text-grey-2 font-display font-bold mr-2"
                    :class="{'text-grey-6': filterType !== 'phn'}"
                >PHN</div>
                <div class="w-150">
                    <v-autocomplete
                        outlined
                        hide-details
                        dense
                        chips
                        small-chips
                        clearable
                        :items="phnArr"
                        v-model="filter.phn"
                        @focusin.prevent="setFilterType('phn')"
                        @change="filterUpdated"
                    ></v-autocomplete>
                </div>
            </div>
            <div class="flex items-center">
                <div
                    class="uppercase text-xs text-grey-2 font-display font-bold mr-2"
                    :class="{'text-grey-6': filterType !== 'mmm'}"
                >MMM</div>
                <div class="w-56">
                    <v-autocomplete
                        :items="mmmArr"
                        outlined
                        hide-details
                        dense
                        chips
                        small-chips
                        clearable
                        v-model="filter.mmm"
                        @focusin.prevent="setFilterType('mmm')"
                        @change="filterUpdated"
                    ></v-autocomplete>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import DateRangePicker from '@/components/partials/DateRangePicker'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import { useSurveyVisitStore } from '@/stores/useSurveyVisitStore'

const { useGetters } = createNamespacedHelpers('')


export default {
    name: 'SurveyVisitHeader',
    components: {DateRangePicker},
    setup() {
        const items = ref([{text: 'foo', value: 'BAR'}])
        const title = ref('Survey Visit Header')
        const filterType = ref('')
        const dateRange = ref([])
        const defaultFilter = ref({
            dateRange: [],
            region: '',
            phn: '',
            mmm: ''
        })
        const filter = ref({
            dateRange: [],
            region: '',
            phn: '',
            mmm: ''
        })
        const { updatePracticeFilter, updatePracticeFilterType } = useSurveyVisitStore()
        const { states, mmmArr, phnArr } = useGetters(['states', 'mmmArr', 'phnArr'])

        //#region functions
        const setFilterType = (value) => {
            filterType.value = value
            updatePracticeFilterType(value)
        }

        const filterUpdated = () => {
            const type = filterType.value
            const backup = filter.value[type]
            const dateRange = filter.value.dateRange
            const newFilter = {...defaultFilter.value, dateRange}
            newFilter[type] = backup
            filter.value = {...newFilter}
            updatePracticeFilter({...filter.value})
        }
        //#endregion

        //#region watches
        watch(dateRange, (newDate) => filter.value.dateRange = newDate)
        watch(() => filter.value?.dateRange, () => updatePracticeFilter({...filter.value}), { deep: true })
        //#endregion

        return {
            title,
            items,
            filterType,
            dateRange,
            filter,
            states,
            mmmArr,
            phnArr,
            setFilterType,
            filterUpdated
        }
    }
}
</script>
