import { helpers, getGoogleMapsAPI } from 'gmap-vue'

const { MapElementFactory } = helpers

export default MapElementFactory({
    name: 'DirectionsRenderer',
    // ctr: () => gmapApi.maps.DirectionsRenderer,
    ctr: () => getGoogleMapsAPI().maps?.DirectionsRenderer,
    events: ['directions_changed'],
    mappedProps: {
        routeIndex: { type: Number },
        options: { type: Object },
        panel: { },
        directions: { type: Object },
    },
    props: {
        origin: { type: Object },
        destination: { type: Object },
        travelMode: { type: String }
    },
    beforeCreate(options) {},
    afterCreate(directionsRenderer) {
        const maps = getGoogleMapsAPI().maps
        let directionsService = maps?.DirectionsService()
        const updateDirections = () => {
            let { origin, destination, travelMode } = this
            if (!origin || !destination || !travelMode) return
    
            directionsService.route(
                {
                    origin,
                    destination,
                    travelMode
                },
                (response, status) => {
                    if (status !== "OK") return
                    directionsRenderer.setDirections(response)
                }
            );
        }
        this.$watch(
            () => [this.origin, this.destination, this.travelMode],
            () => {
                console.log('values within the afterCreate hook', this.origin, this.destination, this.travelMode)
                updateDirections()
            }
        )
    
        //running updatedirections on first load
        updateDirections()
    },
})
