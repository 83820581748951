<template>
    <div class="relative" id="surveyorList">
        <div class="bg-white-pure px-3 flex justify-between items-center absolute h-8 top-0 left-0 right-0 z-6 border-bottom">
            <h2 class="font-bold text-xs uppercase text-black">Surveyors ({{ surveyorsList.length }})</h2>
            <div class="flex items-center">
                <div class="hidden">
                    <SurveyorSort v-model="sortDirection" @sort-surveyors="sortSurveyors"/>
                </div>
                <SurveyorFilter v-model="surveyorFilter" />
            </div>
        </div>
        <div class="pt-8 relative visit-height overflow-y-auto">
            <v-expansion-panels flat>
                <v-expansion-panel
                    v-for="(surveyor, idx) of surveyorsList"
                    :key="idx"
                    class="border-bottom"
                >
                    <div :class=" surveyor.check ? `flex align-center bg-practices` : `flex align-center`" class="w-full">
                        <div class="pl-2">
                            <v-checkbox
                                color="black"
                                class="noLabelCheckbox transition-all duration-500"
                                v-if="idCreate.length"
                                :style="(idCreate.length > 0) ? `--bg-opacity: 0` : `--bg-opacity: 0`"
                                :ripple="false"
                                v-model="surveyor.check"
                                hide-details
                                @change="checkSurveyor(surveyor)"
                            ></v-checkbox>
                        </div>
                        <v-expansion-panel-header class="px-2 py-3 items-center justify-between">           
                            <div class="text-xs flex items-center">
                                {{getFullName(surveyor)}}
                                <GpBadge :gp-type="getGpType(surveyor.isGp)" class="shrink ml-1" />
                                <div class="text-xxs text-grey-2 uppercase font-bold ml-2">{{ getAvailabilityDays(surveyor.availability) }}</div>
                            </div>
                        </v-expansion-panel-header>
                    </div>
                    <v-expansion-panel-content class="bg-grey-8 text-xs border-bottom rounded-none">
                        <div class="uppercase text-grey-2 text-xxs font-bold">Employment</div>
                        <div class="mb-2">{{ getEmploymentType(surveyor.isEmployee) }}</div>
                        <div class="uppercase text-grey-2 text-xxs font-bold">Availability</div>
                        <div class="mb-2">{{ getArrayAsString(surveyor.availability) }}</div>
                        <div class="uppercase text-grey-2 text-xxs font-bold">Rate Classification</div>
                        <div class="">{{ getRateClassification(surveyor.rateClassification) }}</div>
<!--                        <div class="uppercase text-grey-2 text-xxs font-bold">Visits done this year</div>-->
<!--                        <div>{{ surveyor.visitsDone || '-' }}</div>-->
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script>
import { ref, watch, computed, watchEffect } from '@vue/composition-api'
import GpBadge from '@/components/partials/GpBadge'
import SurveyorFilter from '@/components/survey-visit/partials/SurveyorFilter'
import SurveyorSort from '@/components/survey-visit/partials/SurveyorSort'
import { EMPLOYMENT_OPTIONS, GP_TYPES } from '@/config/constants'
import { useNameHelpers } from '@/composables/nameHelpers'
import { useQuery } from '@vue/apollo-composable'
import { GET_SURVEYOR_RATE_CLASSIFICATIONS } from '@/graphql/queries/surveyorQueries.gql'
import camelcaseKeys from 'camelcase-keys'
import { useSurveyVisitStore } from '@/stores/useSurveyVisitStore'
import { storeToRefs } from 'pinia'


export default {
    name: 'VisitSurveyorList',
    components: {GpBadge, SurveyorFilter, SurveyorSort},
    props: {
        idCreate: {
            type: Array,
            default: () => []
        }
    },
    setup(props, { emit }) {
        // console.log('props idCreate: ', props.idCreate)
        // const idCreate = ref(props.idCreate)
        const surveyorFilter = ref({
            gpType: '',
            employment: '',
            availability: [],
            preferredDays: true
        })
        const surveyorCheckedList = ref([])
        const sortDirection = ref('')
        const surveyVisitStore = useSurveyVisitStore()
        const { computedSurveyorsList: surveyors } = storeToRefs(surveyVisitStore)
        const { updateSurveyorFilter, updateSurveyorsList } = surveyVisitStore
        const surveyorsList = ref([])
        const { getFullName } = useNameHelpers()
        const classifications = ref([])
        const {onResult, onError} = useQuery(GET_SURVEYOR_RATE_CLASSIFICATIONS)

        //#region apollo callbacks
        onResult(({ data: { rateClassifications } }) => {
            classifications.value = camelcaseKeys(rateClassifications || [], {deep: true})
        })

        onError((error) => {
            console.log('error', error)
            // TODO: need to show error message somewhere
        })
        //#endregion

        //#region functions

        const getArrayAsString = (list) => {
            if (!(list instanceof Array)) return list || '-'
            return list?.join(', ') || '-'
        }

        const getAvailabilityDays = (list) => {
            const newList = list.map(e => e.slice(0,3))
            return newList.length === 7 ? "All DAYS" : newList.join(', ').toUpperCase()
        }

        const getGpType = (gpType) => {
            return gpType ? 'gp' : 'non-gp'
        }

        const getEmploymentType = (isEmployee) => {
            return isEmployee ? 'Employee' : 'Contractor'
        }

        const sortSurveyors = () => {
            if (!sortDirection.value) return

            // minus sign in text corresponds to desc order
            const idxOfMinus = sortDirection.value.indexOf('-')
            surveyorsList.value.sort((a, b) => {
                const left = classifications.value.find(c => c.id === a.rateClassification)?.hourlyRate
                const right = classifications.value.find(c => c.id === b.rateClassification)?.hourlyRate
                if (idxOfMinus > -1) {
                    return left < right ? -1 : left > right ? 1 : 0
                }
                return left > right ? -1 : right < left ? 1 : 0
            })
        }

        const filterSurveyors = (arr, filter) => {
            arr = arr.filter(s => {
                if (!filter.gpType) return true

                switch (filter.gpType.toLocaleLowerCase()) {
                    case GP_TYPES.Any:
                        return true
                    case GP_TYPES.Gp:
                        return s.isGp
                    case GP_TYPES.NonGp:
                        return !s.isGp
                    default:
                        return false
                }
            })

            //now filtering by employment
            arr = arr.filter(s => {
                if (!filter.employment) return true

                switch (filter.employment.toLocaleLowerCase()) {
                    case EMPLOYMENT_OPTIONS.Any:
                        return true
                    case EMPLOYMENT_OPTIONS.FullTime:
                        return s.isEmployee
                    case EMPLOYMENT_OPTIONS.Contract:
                        return !s.isEmployee
                    default:
                        return false
                }
            })

            //now filtering by availability
            arr = arr.filter(s => {
                if (!filter.availability?.length) return true

                const filterSet = new Set(filter.availability)
                const intersection = new Set(
                    s.availability.filter(s => filterSet.has(s.toLowerCase()))
                )
                return intersection.size > 0
            })

            // now filter by cost rating
            arr = arr.filter(s => {
                if (!filter.costRating || filter.costRating === 'ANY') return true

                return s.rateClassification === Number(filter.costRating)
            })

            return arr
        }

        const getRateClassification = (rateClassification) => {
            const classification = classifications.value.find(c => c.id === rateClassification)
            return classification?.description || '-'
        }

        const checkSurveyor = (surveyor) => {
            if(surveyor.check) surveyorCheckedList.value.push(surveyor.id)
            else surveyorCheckedList.value.splice(surveyorCheckedList.value.indexOf(surveyor.id),1)
        }
        //#endregion
        
        watch(() => surveyorCheckedList.value, async (value) => {
            emit('eventCheckSurveyor', value)
        })

        //#region watches props.idCreate
        watch(() => props.idCreate, (idCreate) => {
            surveyorCheckedList.value = []
            if(idCreate.length === 0){
                surveyorsList.value = surveyorsList.value.map((item)=>{
                    return {...item, check: false}
                })
            }
        })
        //#endregion watches

        //#region watches surveyorFilter.value
        watch(() => surveyorFilter.value, (newFilter) => {
            surveyorsList.value = filterSurveyors([...surveyors.value], newFilter)
            updateSurveyorsList(surveyorsList.value)
            updateSurveyorFilter(newFilter)
        })
        //#endregion

        //#region watchEffect
        watchEffect(() => {
            surveyorsList.value = filterSurveyors([...surveyors.value], surveyorFilter.value)
            sortDirection.value = ''
        })
        //#endregion

        return {
            surveyorCheckedList,
            surveyors,
            surveyorsList,
            surveyorFilter,
            sortDirection,
            getFullName,
            getArrayAsString,
            getGpType,
            getEmploymentType,
            sortSurveyors,
            getRateClassification,
            getAvailabilityDays,
            checkSurveyor,
        }
    },
    data () {
      return {
        checkbox: true,
      }
    }
}
</script>
