<template>
    <v-menu
        bottom left offset-y
        :close-on-content-click="false"
        :nudge-width="200"
        :value="showSort"
    >
        <template v-slot:activator="{ on, attrs }">
            <div class="text-black text-xs flex flex-col" v-bind="attrs" v-on="on" @click="updateShowSort">
                <fa-icon icon="sort" class="text-xs text-black px-3" />
            </div>
        </template>
        <div class="p-3 text-xs bg-white-pure">
            <div class="uppercase text-grey-2 text-xxs font-bold">Cost Rating</div>
            <div class="w-150 mb-4">
                <v-autocomplete
                    outlined hide-details
                    dense
                    chips
                    small-chips
                    :items="costRatingSort"
                    v-model="sortDirection"
                ></v-autocomplete>
            </div>
            <div class="flex items-center">
                <v-btn
                    depressed outlined text
                    :ripple="false"
                    :small="true"
                    color="white"
                    class="border border-grey-4 mr-2"
                    @click="clearSort"
                >Clear sort</v-btn>
                <v-btn
                    :ripple="false"
                    :small="true"
                    color="primary"
                    depressed
                    @click="updateSort"
                >Update</v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
import { ref, onMounted, watch, computed } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'SurveyorSort',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    emits: ['sort-surveyors'],
    setup(props, { emit }) {
        const sortDirection = ref('')
        const showSort = ref(false)
        const { costRatingSort } = useGetters(['costRatingSort'])

        //#region life cycle hooks
        onMounted(() => {
            sortDirection.value = props.value
        })
        //#endregion

        //#region functions
        const closeSort = () => {
            showSort.value = false
        }

        const clearSort = () => {
            sortDirection.value = ''
            closeSort()
        }

        const updateSort = () => {
            emit('input', sortDirection.value)
            emit('sort-surveyors')
            closeSort()
        }

        const updateShowSort = () => {
            showSort.value = true
        }
        //#endregion

        //#region computed properties
        const computedValue = computed(() => props.value)
        //#endregion

        //#region watches
        // commenting out the watch for now since we don't need it
        watch(() => computedValue.value, (newValue) => sortDirection.value = newValue)
        //#endregion

        return {
            showSort,
            sortDirection,
            costRatingSort,
            clearSort,
            updateSort,
            updateShowSort
        }
    }
}
</script>

