import { ref, computed } from '@vue/composition-api'
import { getGoogleMapsAPI } from 'gmap-vue'
import { CLICKED_SCALE, DEFAULT_OPACITY, DEFAULT_SCALE, INACTIVE_OPACITY } from '@/config/constants'
import { splitListToChunk } from '@/utils/array.helper'
import { storeToRefs } from 'pinia'
import { useSurveyVisitStore } from '@/stores/useSurveyVisitStore'


export function useSurveyMap(variables) {
    const svMap = ref({})
    const center = ref({lat: -33.8688, lng: 151.2093})
    const closestSurveyor = ref(null)
    const closestSurveyorNonGp = ref(null)
    const surveyVisitStore = useSurveyVisitStore()
    const { computedSurveyorsList: surveyors } = storeToRefs(surveyVisitStore)
    const practiceMarkers = ref([])
    const surveyorMarkers = ref([])
    const practiceLatLngList = ref([])
    const surveyorLatLngList = ref([])
    const closestGpInfoWindow = ref(null)
    const closestNonGpInfoWindow = ref(null)
    const selectedPractice = ref({})
    const selectedSurveyor = ref({})
    const surveyorDistance = ref({})
    const selectedSurveyorDistance = ref({})
    
    //#region functions
    const setClickedSurveyor = (surveyor) => {
        selectedSurveyor.value = {...surveyor}
        const listNames = surveyors.value.map(e => e.name)
        const index = listNames.indexOf(`${selectedSurveyor.value.name}`)
        selectedSurveyorDistance.value = surveyorDistance.value[index]?.elements
    }
    
    const setClickedPractice = (practice) => {
        selectedSurveyor.value = {}
        selectedPractice.value = {...practice}
    }
    
    const resetSelectedMarkers = () => {
        selectedPractice.value = {}
        selectedSurveyor.value = {}
        selectedSurveyorDistance.value = {}
    }
    
    const changeCenter = (marker) => {
        center.value = marker.position
    }
    
    const changeZoom = (latLngList) => {
        const bounds = new google.value.maps.LatLngBounds()
        for (const latLng of latLngList) {
            bounds.extend(latLng)
        }
        svMap.value.fitBounds(bounds)
    }
    
    const clearPracticeMarkers = () => {
        for (const marker of practiceMarkers.value) {
            marker.visible = false
            marker.setMap(null)
        }
        practiceMarkers.value = []
    }
    
    const clearSurveyorMarkers = () => {
        for (const marker of surveyorMarkers.value) {
            marker.visible = false
            marker.setMap(null)
        }
        surveyorMarkers.value = []
    }
    
    const clearClosestMarkers = () => {
        closestSurveyor.value = null
        closestSurveyorNonGp.value = null
    }

    /**
     * 
     * @param name
     * @param distanceAndDuration
     * @returns {{infoWindow: google.value.maps.InfoWindow}}
     */
    const createNewInfoWindow = (name, distanceAndDuration = {}) => {
        const { distance, duration } = distanceAndDuration
        //TODO: move the content string into constants, for now, here is fine
        const bodyContent = distance ? `
        <b>
            <b>Travel time</b>: ${duration.text} <br />
            <b>Distance to practice</b>: ${distance.text} <br />
        </p>
        ` : ''
        const contentString = `
            <div id="content">
                <div id="siteNotice"></div>
                <h2 id="secondHeading" class="secondHeading text-sm font-bold font-display text-black">${name}</h2>
                <div id="bodyContent" class="text-xs font-body text-black">
                    ${bodyContent}
                </div>
            </div>
        `
        return new google.value.maps.InfoWindow({content: contentString,})
    }
    
    /**
     * function to create a marker for google maps - use arguments to create a custom icon instead of the default red
     *
     * @param address
     * @param addCustomIcon
     * @param opacity
     * @param colour
     * @param overlayMarker
     * @param name
     * @param showLabel
     * @returns {{marker, latLng: google.value.maps.LatLng}}
     */
    const createNewMarker = (
        address, addCustomIcon, opacity = DEFAULT_OPACITY, colour = 'blue',
        overlayMarker = true, name = '', showLabel = false
    ) => {
        const latLng = new google.value.maps.LatLng(address.lat, address.lng)
        const point = overlayMarker ? new google.value.maps.Point(15, 25) : new google.value.maps.Point(12, 22)
        const markerOptions = {
            opacity,
            clickable: true,
            draggable: false,
            position: latLng,
            visible: true,
            optimized: false,
            map: svMap.value.$mapObject
        }
        markerOptions.title = name
        // if (showLabel) markerOptions.label = name
        if (addCustomIcon) {
            //TODO: move this svg to a different file and/or find a better way to add
            markerOptions.icon = {
                // url: 'http://localhost:8080/assets/marker.svg',
                // path: "M7.65957447,6.39488462e-14 C9.71702128,6.39488462e-14 11.4542553,0.670351759 12.8723404,2.01005025 C14.2904255,3.34974874 15,4.9919598 15,6.93467337 C15,7.93969849 14.7521277,9.04522613 14.2553191,10.2512563 C13.7585106,11.4572864 13.1202128,12.6301508 12.3404255,13.7688442 C11.5606383,14.9075377 10.8510638,15.9467337 10.212766,16.8844221 C9.57446809,17.8221106 8.9712766,18.5929648 8.40425532,19.1959799 L7.55319149,20 C7.34042553,19.798995 7.05744681,19.4974874 6.70212766,19.0954774 C6.34787234,18.6934673 5.74468085,17.9567839 4.89361702,16.8844221 C4.04255319,15.8120603 3.29787234,14.7738693 2.65957447,13.7688442 C2.0212766,12.7638191 1.41808511,11.6251256 0.85106383,10.3517588 C0.284042553,9.07839196 -7.10542736e-15,7.93969849 -7.10542736e-15,6.93467337 C-7.10542736e-15,4.9919598 0.709574468,3.34974874 2.12765957,2.01005025 C3.54574468,0.670351759 5.28404255,6.39488462e-14 7.34042553,6.39488462e-14 L7.65957447,6.39488462e-14 Z M7.50031915,4.62110553 C5.92797872,4.62110553 4.65244681,5.82512563 4.65244681,7.31055276 C4.65244681,8.7959799 5.92797872,10.0966667 7.50031915,10.0966667 C9.07265957,10.0966667 10.3471277,8.7959799 10.3471277,7.31055276 C10.3471277,5.82512563 9.07265957,4.62110553 7.50031915,4.62110553 Z",
                // path: "M20.4255319,6.39488462e-14 C25.9120567,6.39488462e-14 30.5446809,1.85635872 34.3262411,5.566293 C38.1078014,9.27622729 40,13.8238887 40,19.2037109 C40,21.9868574 39.3390071,25.0483185 38.0141844,28.3880943 C36.6893617,31.7278701 34.987234,34.9758021 32.9078014,38.1291071 C30.8283688,41.2824121 28.9361702,44.1601855 27.2340426,46.7568612 C25.5319149,49.3535369 23.9234043,51.4882103 22.4113475,53.1580982 L20.141844,55.3846154 C19.5744681,54.8279861 18.8198582,53.9930421 17.8723404,52.8797835 C16.9276596,51.7665249 15.3191489,49.7264785 13.0496454,46.7568612 C10.7801418,43.7872439 8.79432624,40.9122536 7.09219858,38.1291071 C5.39007092,35.3459606 3.78156028,32.1926556 2.26950355,28.666409 C0.757446809,25.1401624 -7.10542736e-15,21.9868574 -7.10542736e-15,19.2037109 C-7.10542736e-15,13.8238887 1.89219858,9.27622729 5.67375887,5.566293 C9.45531915,1.85635872 14.0907801,6.39488462e-14 19.5744681,6.39488462e-14 L20.4255319,6.39488462e-14 Z M20.0008511,12.7969076 C15.8079433,12.7969076 12.4065248,16.1311171 12.4065248,20.2446077 C12.4065248,24.3580982 15.8079433,27.96 20.0008511,27.96 C24.1937589,27.96 27.5923404,24.3580982 27.5923404,20.2446077 C27.5923404,16.1311171 24.1937589,12.7969076 20.0008511,12.7969076 Z",
                path: "M7.65957447,3.19744231e-14 C9.71702128,3.19744231e-14 11.4542553,0.670351759 12.8723404,2.01005025 C14.2904255,3.34974874 15,4.9919598 15,6.93467337 C15,7.93969849 14.7521277,9.04522613 14.2553191,10.2512563 C13.7585106,11.4572864 13.1202128,12.6301508 12.3404255,13.7688442 C11.5606383,14.9075377 10.8510638,15.9467337 10.212766,16.8844221 C9.57446809,17.8221106 8.9712766,18.5929648 8.40425532,19.1959799 L7.55319149,20 C7.34042553,19.798995 7.05744681,19.4974874 6.70212766,19.0954774 C6.34787234,18.6934673 5.74468085,17.9567839 4.89361702,16.8844221 C4.04255319,15.8120603 3.29787234,14.7738693 2.65957447,13.7688442 C2.0212766,12.7638191 1.41808511,11.6251256 0.85106383,10.3517588 C0.284042553,9.07839196 -3.55271368e-15,7.93969849 -3.55271368e-15,6.93467337 C-3.55271368e-15,4.9919598 0.709574468,3.34974874 2.12765957,2.01005025 C3.54574468,0.670351759 5.28404255,3.19744231e-14 7.34042553,3.19744231e-14 L7.65957447,3.19744231e-14 Z M7.50031915,4.62110553 C5.92797872,4.62110553 4.65244681,5.82512563 4.65244681,7.31055276 C4.65244681,8.7959799 5.92797872,10.0966667 7.50031915,10.0966667 C9.07265957,10.0966667 10.3471277,8.7959799 10.3471277,7.31055276 C10.3471277,5.82512563 9.07265957,4.62110553 7.50031915,4.62110553 Z",
                fillColor: colour,
                fillOpacity: opacity, // if not close, need to change to 0.2
                // fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0,
                scale: DEFAULT_SCALE,
                anchor: point,
            }
        }
        const marker = new google.value.maps.Marker(markerOptions)
        
        return {
            latLng,
            marker
        }
    }

    const callDistanceApi = async (marker, origins) => {
        const destinations = [marker.position]
        const directionsService = new google.value.maps.DistanceMatrixService()
        const request = {
            origins,
            destinations,
            travelMode: google.value.maps.TravelMode.DRIVING,
            unitSystem: google.value.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
        }
        const result = await directionsService.getDistanceMatrix(request)

        return result.rows
    }
    
    const setClosestSurveyor = (idx, distance = null, surveyor = null) => {
        // case when we need to reset the index
        if (idx === null) {
            closestSurveyor.value = null
            closestSurveyorNonGp.value = null
            return
        }
        // needs to add the distance onto the surveyor so we can pass it to the createNewInfoWindow
        const distanceAndDuration = distance?.elements[0]
        if (surveyor.isGp) closestSurveyor.value = {...surveyor, distanceAndDuration}
        else if (!surveyor.isGp) closestSurveyorNonGp.value = {...surveyor, distanceAndDuration}
    }
    
    /**
     * function to scale the current marker, leaving here for now
     * if unused, then remove, when a marker is removed, edited and then added again the map doesn't redraw
     * it might be due to the way the map is, it could be lazily loaded
     * @param marker
     */
    const setPracticeLabel = (marker) => {
        //reset practice markers and change scale of the currently clicked one
        for (const m of practiceMarkers.value) {
            const practice = m.csPractice

            // if (m.icon.opacity === INACTIVE_OPACITY) continue
            const opacity = marker.csPractice?.practiceId === practice.practiceId ? DEFAULT_OPACITY : INACTIVE_OPACITY
            const scale = marker.csPractice?.practiceId === practice.practiceId ?  CLICKED_SCALE : DEFAULT_SCALE
            // const title = marker.csPractice?.id === practice.id ? practice.name : ''
            const icon = m.icon
            icon.fillOpacity = opacity
            icon.scale = scale
            // icon.scale = scale
            m.setOptions({'opacity': opacity})
            m.setOptions({'icon': {...icon}})
            // m.title = marker.csPractice?.id === practice.id ? practice.name : ''
            // m.title = practice.name || ''
            // m.icon.opacity = marker.csPractice?.id === practice.id ? DEFAULT_OPACITY : INACTIVE_OPACITY
        }
    }
    
    const resetMarkers = () => {
        clearClosestMarkers()
        resetSelectedMarkers()
        for (const m of practiceMarkers.value) {
            const icon = m.icon
            icon.fillOpacity = DEFAULT_OPACITY
            icon.scale = DEFAULT_SCALE
            // icon.scale = scale
            m.setOptions({'opacity': DEFAULT_OPACITY})
            m.setOptions({'icon': {...icon}})
        }
        for (const s of surveyorMarkers.value) {
            const icon = s.icon
            icon.fillOpacity = DEFAULT_OPACITY
            icon.scale = DEFAULT_SCALE
            // icon.scale = scale
            s.setOptions({'opacity': DEFAULT_OPACITY})
            s.setOptions({'icon': {...icon}})
        }
    }
    
    const getClosestSurveyors = async (marker, surveyorMarkersLocal) => {
        //let's see if we can use svMap instead
        setClickedPractice(marker.csPractice)
        
        closestSurveyor.value = null
        closestSurveyorNonGp.value = null
        setPracticeLabel(marker)
        // setPracticeLabel(marker)
        // now we loop through each surveyor and see which is closer
        const origins = surveyorMarkersLocal.map(sv => sv.position)
        // const destinations = [marker.position]
        const listOrigins = [...origins] 
        const newList = splitListToChunk(listOrigins)
        
        const result = await Promise.all(newList.map(async (item) => {
            return await callDistanceApi(marker, item)
        }))    

        try {
            const response = result.flat()
            // console.log("res", response)
            surveyorDistance.value = response
            // let closestMarker = null
            let idx = null
            
            const filteredSurveyors = surveyors.value.filter(s => s.lat !== null)
            let closestMarker = null
            // we assume the index matches the surveyor
            for(let i = 0; i < response.length; i++) {
                const distanceAndDuration = response[i]?.elements[0]
                filteredSurveyors[i] = {...filteredSurveyors[i], distance: {...distanceAndDuration}}
                if (!closestMarker) {
                    //since we have only 1 destination, the one we want should  be in the '0' index
                    closestMarker = response[i].elements[0]
                    setClosestSurveyor(i, response[i], filteredSurveyors[i])
                    continue
                }
                // now we check the closest Gp and nonGP distances
                const checkDestination = response[i].elements[0]
                const gpDistance = closestSurveyor.value?.distanceAndDuration
                const nonGpDistance = closestSurveyorNonGp.value?.distanceAndDuration
                const surveyor = filteredSurveyors[i]
                const markerDistance = surveyor.isGp ? gpDistance?.distance?.value : nonGpDistance?.distance?.value
                if (checkDestination?.distance?.value < markerDistance || !markerDistance) {
                    closestMarker = {...checkDestination}
                    setClosestSurveyor(i, response[i], surveyor)
                    idx = i
                }
            }
            if (idx === null) setClosestSurveyor(null)
        } catch (e) {
            console.log('error', e)
        }
    }
    
    const getSurveyorBgClass = (surveyor) => {
        if (surveyor.isGp) return 'bg-light-blue'
        else return 'bg-orange'
    }
    //#endregion
    
    //#region computed properties
    const google = computed(getGoogleMapsAPI)
    
    const reloadMap = () => {
        resetMarkers()
        setClosestSurveyor(null)
        google.value?.maps?.event?.trigger?.(svMap.value, 'resize')
    }
    // const selectedMarker = computed(() => {
    //     const retVal = {name: '', background: ''}
    //     retVal.name = selectedPractice.value?.name || selectedSurveyor.value?.name
    //     if (selectedPractice.value) retVal.background = 'bg-black'
    //     if (selectedSurveyor.value) {
    //         if (selectedSurveyor.value.isGp) retVal.background = 'bg-green'
    //         else retVal.background = 'bg-orange'
    //     }
    //
    //     return retVal
    // })
    //#endregion
    
    return {
        svMap, center, practiceMarkers, surveyorMarkers, practiceLatLngList, surveyorLatLngList,
        closestSurveyor, closestSurveyorNonGp, closestGpInfoWindow, closestNonGpInfoWindow, // selectedMarker,
        selectedPractice, selectedSurveyor, selectedSurveyorDistance, changeCenter, changeZoom,
        clearPracticeMarkers, clearSurveyorMarkers, createNewMarker, getClosestSurveyors, clearClosestMarkers,
        createNewInfoWindow, setClickedSurveyor, getSurveyorBgClass, reloadMap
    }
}
