import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"bottom":"","left":"","offset-y":"","close-on-content-click":false,"nudge-width":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('fa-icon',_vm._g(_vm._b({staticClass:"text-black text-xs px-3",attrs:{"icon":"filter"}},'fa-icon',attrs,false),on))]}}])},[_c('div',{staticClass:"p-3 text-xxs bg-white-pure uppercase font-bold text-center"},[_vm._v("Filtering coming soon...")]),_c('div',{staticClass:"p-3 text-xxs bg-white-pure"},[_c('div',{staticClass:"uppercase text-grey-2 text-xs font-bold"},[_vm._v("Tags")]),_c('div',{staticClass:"w-150 mb-4"},[_c(VAutocomplete,{attrs:{"items":_vm.computedItems,"outlined":"","hide-details":"","dense":"","chips":"","small-chips":"","disabled":""}})],1),_c('div',{staticClass:"flex items-center"},[_c(VBtn,{staticClass:"border border-grey-4 mr-2",attrs:{"depressed":"","outlined":"","text":"","disabled":"","ripple":false,"small":true,"color":"white"}},[_vm._v("Clear Filters")]),_c(VBtn,{attrs:{"disabled":"","small":true,"ripple":false,"color":"primary","depressed":""}},[_vm._v("Update")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }