import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'

export default class PracticeResource {
    
    async getPracticeList(startDate, endDate, filters) {
        let practices = []
        try {
            let data = {
                date_start: startDate,
                date_end: endDate,
            }
            if (filters.region) data.region = filters.region
            if (filters.mmm) data.mmm = filters.mmm
            if (filters.phn) data.phn = filters.phn
            const response = await axios.post(`svplanner/get_practices/`, data)

            practices = camelcaseKeys(response?.data || [], { deep: true })
        } catch (e) {
            console.log('error', e)
        }
        return practices
    }
    
    async getPracticeAndSurveyors(startDate, endDate, filters) {
        let practicesAndSurveyors = []
        try {
            let data = {
                date_start: startDate,
                date_end: endDate,
            }
            if (filters.region) data.region = filters.region
            if (filters.mmm) data.mmm = filters.mmm
            if (filters.phn) data.phn = filters.phn
            if (filters.practiceIds) data.practiceIds = filters.practiceIds
            const response = await axios.post(`svplanner/get_practices_and_surveyors/`, data)
        
            practicesAndSurveyors = camelcaseKeys(response?.data || [], { deep: true })
        } catch (e) {
            console.log('error', e)
        }
        return practicesAndSurveyors
    }
}
