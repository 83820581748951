<template>
    <div class="flex flex-col h-full">
        <SurveyVisitHeader />
        <div class="relative grid grid-cols-12 h-full border border-grey-4 border-l-0 border-r-0 border-t-0">
            <div v-show="isFirstVisit" class="whiteOverlay"></div>
            <div v-show="isFirstVisit" class="absolute" id="svHelpMessage">
                <img src="@/assets/images/surveyvisitplanning.png" class="m-auto mb-1" :style="imgStyle" alt="Start self assessment" />
                <div class="text-black text-lg font-bold">Let's start planning survey visits!</div>
                <div class="text-grey-1 text-sm">
                    <b class="text-xs text-primary-light">STEP 1:</b> Select a date range to display practices with survey visits due.
                    <br/><b class="text-xs text-primary-light">STEP 2:</b>  Select between a region, PHN <u>OR</u> MMM to filter by location.
                </div>
            </div>

            <SurveyVisitFilters class="col-span-6" />
            <SurveyVisitMap class="col-span-6" />
        </div>
    </div>
</template>

<script>
import SurveyVisitHeader from '@/components/survey-visit/SurveyVisitHeader'
import SurveyVisitFilters from '@/components/survey-visit/SurveyVisitFilters'
import SurveyVisitMap from '@/components/survey-visit/SurveyVisitMap'
import { useSurveyVisitStore } from '@/stores/useSurveyVisitStore'
import { ref, computed } from '@vue/composition-api'

export default {
    name: 'SurveyVisitPlanning',
    components: {SurveyVisitHeader, SurveyVisitFilters, SurveyVisitMap},
    setup() {
        const surveyVisitStore = useSurveyVisitStore()
        const isFirstVisit = computed(() => surveyVisitStore.computedFirstMapVisit)
        const imgStyle = ref({
            width: '300px',
            height: 'auto',
        })

        //#endregion
        return {
            imgStyle,
            isFirstVisit
        }
    },
}
</script>
