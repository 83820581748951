<template>
    <v-menu
        bottom left offset-y
        :close-on-content-click="false"
        :nudge-width="200"
        :value="showSort"
    >
        <template v-slot:activator="{ on, attrs }">
            <div class="text-black text-xs flex flex-col" v-bind="attrs" v-on="on" @click="updateShowSort">
                <fa-icon icon="sort" class="text-xs text-black px-3" />
            </div>
        </template>
        <div class="p-3 text-xs bg-white-pure">
            <div class="uppercase text-grey-2 text-xxs font-bold">Expiry</div>
            <div class="w-150 mb-4">
                <v-autocomplete
                    outlined hide-details
                    dense
                    chips
                    small-chips
                    :items="expirySort"
                    v-model="sortDirection"
                ></v-autocomplete>
            </div>
            <div class="flex items-center">
                <v-btn
                    depressed outlined text
                    :ripple="false"
                    :small="true"
                    color="white"
                    class="border border-grey-4 mr-2"
                    @click="clearSort"
                >Clear sort</v-btn>
                <v-btn
                    :ripple="false"
                    color="primary"
                    :small="true"
                    depressed
                    @click="updateSort"
                >Update</v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'PracticeListSort',
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    emits: ['sort-practices'],
    setup(props, { emit }) {
        const showSort = ref(false)
        const sortDirection = ref('')
        const { expirySort } = useGetters(['expirySort'])

        //#region lifecycle hooks
        onMounted(() => {
            sortDirection.value = props.value
        })
        //#endregion

        //#region functions
        const closeSort = () => {
            showSort.value = false
        }

        const clearSort = () => {
            sortDirection.value = ''
            closeSort()
        }

        const updateSort = () => {
            emit('input', sortDirection.value)
            emit('sort-practices')
            closeSort()
        }

        const updateShowSort = () => {
            showSort.value = true
        }
        //#endregion

        return {
            expirySort,
            showSort,
            sortDirection,
            clearSort,
            updateShowSort,
            updateSort,
        }
    }
}
</script>

