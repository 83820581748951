<template>
    <div class="relative" id="practiceList">
        <div class="bg-white-pure pl-3 flex justify-between items-center absolute h-8 top-0 left-0 right-0 z-6 border-bottom">
            <h2 class="pl-9 font-bold text-xs uppercase text-black">Practices ({{ practices.length }})</h2>
            <div class="flex items-center">
                <PracticeListSort v-model="sortDirection" @sort-practices="sortPractices" />

                <PracticeFiltersMenu :items="items" />
            </div>
        </div>
        <div v-if="practicesLoaded && practices.length" class="pt-8 relative visit-height overflow-y-auto">
            <div class="pl-2 absolute top-0 left-0 z-6 flex items-center" id="checkAll">
                <v-checkbox
                    title="Check All/Uncheck All"
                    :hide-details="true"
                    :ripple="false"
                    v-model="checkbox"
                    @click="checkAll()"
                ></v-checkbox>
            </div>
            <div
                v-for="(practice, idx) in practices"
                :key="idx"
                :class="(idCreate.length > 0 && idCreate.indexOf(practice.practiceId) >= 0) ? `px-2 py-3 text-xs border-bottom bg-practices` : `px-2 py-3 text-xs border-bottom`"
            >
                <div class="flex justify-between items-start w-full">
                    <div v-if="idCreate.length === 0 || idCreate.indexOf(practice.practiceId) >= 0" style="order: 2;" class="transition-all duration-500">
                        <v-btn
                            v-if="idCreate.indexOf(practice.practiceId) < 0"
                            :small="true"
                            style="color: #779EB7; background-color: white; box-shadow: none; border: 1px solid rgb(245, 245, 245);"
                            rel="noreferrer noopener"
                            class="group uppercase text-xs font-bold text-primary-light hover:text-primary cursor-pointer"
                            @click.prevent="showButton(practice, 'create')"
                        >
                            <fa-icon icon="plus" class="mr-1 text-xs"/> Create Visit
                        </v-btn>
                        <div v-if="idCreate.indexOf(practice.practiceId) >= 0" style="order: 2; display: grid;">
                            <v-tooltip top color="black" :disabled="surveyorCheckedList.length === 0 ? false : true">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs"
                                        v-on="on">
                                        <v-btn
                                            :small="true"
                                            :depressed="true"
                                            :ripple="false"
                                            rel="noreferrer noopener"
                                            class="group uppercase text-xs font-bold text-primary-light hover:text-primary cursor-pointer"
                                            color="white"
                                            :disabled="surveyorCheckedList.length === 0 ? true : false"
                                            @click.prevent="showDialog(surveyorList, surveyorCheckedList, practice)"
                                        >
                                            <fa-icon icon="check" class="mr-1 text-xs text-primary"/> 
                                            <span class="text-primary">FINISH</span>
                                        </v-btn>
                                    </div>

                                </template>
                                <span>Select at least 1 surveyor</span>
                            </v-tooltip>
                            <template>
                                <v-dialog
                                    v-model="dialog"
                                    width="40%"
                                    persistent
                                >
                                    <v-card 
                                        class="flex flex-col justify-between"
                                    >
                                        <div class="px-8 py-4">
                                            <h3 class="text-center m-auto ">Survey Visit</h3>
                                        </div>
                                        <div class="w-full px-8">
                                            <div class="grid grid-cols-2 gap-4">
                                                <div class="col-span-1">
                                                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">PRACTICE NAME</label>
                                                    <p class="text-grey-1 text-sm">{{practice.name}}</p>
                                                </div>
                                                <div class="col-span-1">
                                                    <label class="block font-bold font-display text-xs text-grey-2 uppercase leading-tight">VISIT TYPE</label>
                                                    <p class="text-grey-1 text-sm">{{practice.svType.charAt(0) + practice.svType.slice(1).toLowerCase()}}</p>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <h2 class="text-lg lg:text-xl">Suveyor(s)</h2>
                                                <div class="relative">
                                                    <v-data-table
                                                        class="c-table"
                                                        :headers="headers"
                                                        :items="tableData"
                                                        hide-default-footer
                                                    >
                                                        <template v-slot:item="{item}">
                                                            <tr>
                                                                <td>
                                                                    <div class="flex items-center">
                                                                        {{ item.name }}
                                                                        <GpBadge :gp-type="getGpType(item.isGp)" class="shrink ml-2" />
                                                                    </div>
                                                                </td>
                                                                <td class="text-xs-right"> {{ item.distance }} </td>
                                                                <td class="text-xs-right">
                                                                    <v-icon
                                                                        small
                                                                        @click="deleteItem(item, surveyorList)"
                                                                    >
                                                                        mdi-delete
                                                                    </v-icon>
                                                                </td>
                                                            </tr>
                                                        </template>
                                                    </v-data-table>
                                                    <span 
                                                        class="hidden"
                                                        ref="table"
                                                    >Please select at least 1 surveyor</span>
                                                </div>
                                                <div class="mt-4 relative pb-4">
                                                    <v-select
                                                        v-model="leader"
                                                        :ripple="false"
                                                        :dense="true"
                                                        :hide-details="true"
                                                        label="Choose lead surveyor"
                                                        :items="tableData.map(i => i.name)"
                                                        class="w-1/3"
                                                        @change="setLeader"
                                                    >
                                                    </v-select>
                                                    <span 
                                                        class="hidden"
                                                        ref="leader"
                                                    >Please choose the lead surveyor</span>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <h2 class="text-lg lg:text-xl">Visit Date & Time</h2>
                                                <v-radio-group
                                                    v-model="radio"
                                                    row
                                                    class="w-full radioGroupGrid"
                                                >
                                                    <div class="col-span-1 relative pb-4">
                                                        <div class="flex items-center">
                                                            <v-radio
                                                                :ripple="false"                                           
                                                                value="radio-1"
                                                                class="w-5"
                                                            ></v-radio>
                                                            <v-select
                                                                :hide-details="true"
                                                                :dense="true"
                                                                :value="availDate"
                                                                :items="allowedDates"
                                                                class="mt-0 w-full"
                                                                label="Select from available dates"
                                                                @click="radio='radio-1'"
                                                                @change="setAvailDate"
                                                            ></v-select>
                                                            <span 
                                                                class="hidden"
                                                                ref="availDate"
                                                            >Please select 1 day</span>
                                                        </div>
                                                    </div>
                                                    <div class="col-span-1 relative pb-4">
                                                        <div class="flex items-center">
                                                            <v-radio
                                                                :ripple="false"
                                                                value="radio-2"
                                                                class="w-5"
                                                            ></v-radio>
                                                            <v-menu
                                                                v-model="menu"
                                                                :close-on-content-click="false"
                                                                max-width="290"
                                                            >
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-text-field
                                                                    clearable
                                                                    label="Override with any date"
                                                                    :value="date"
                                                                    :hide-details="true"
                                                                    :dense="true"
                                                                    readonly
                                                                    class="mt-0"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    @click="radio='radio-2'"
                                                                    @click:clear="date = null"
                                                                ></v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="date"
                                                                    :hide-details="true"
                                                                    :dense="true"
                                                                    :allowed-dates="validDates"
                                                                    no-title
                                                                    @change="setDate"
                                                                ></v-date-picker>
                                                            </v-menu>
                                                            <span 
                                                                class="hidden"
                                                                ref="date"
                                                            >Please select 1 day</span>
                                                        </div>
                                                    </div>
                                                </v-radio-group>
                                                <div class="flex items-center relative">
                                                    <div class="mr-4">
                                                        <v-text-field
                                                            :ripple="false"
                                                            :dense="true"
                                                            :hide-details="true"
                                                            v-model="formData.start_time"
                                                            label="Start time"
                                                            value="09:00"
                                                            type="time"
                                                            @change="setStartTime"
                                                        ></v-text-field>
                                                    </div>
                                                    <div class="w-2/5">
                                                        <v-select
                                                            v-model="currentTemplate"
                                                            :ripple="false"
                                                            :dense="true"
                                                            :hide-details="true"
                                                            label="Confirmation Form Template"
                                                            :items="templateList"
                                                            @change="setConfirmationTemplate"
                                                        ></v-select>
                                                        <span 
                                                            class="hidden"
                                                            ref="template"
                                                        >Please choose 1 template</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <v-card-actions class="flex flex-row justify-center px-8 py-4 mt-4">
                                            <v-btn
                                                depressed
                                                :ripple="false"
                                                color="default"
                                                class="mr-4 font-bold el-button uppercase w-40 text-grey-3 font-display el-button--default"
                                                @click="resetFormData"
                                            >
                                                Cancel
                                            </v-btn>
                                            <v-btn
                                                depressed
                                                :ripple="false"
                                                color="primary"
                                                class="font-bold uppercase w-40 font-display"
                                                :disabled="disable"
                                                @click="submitFormData"
                                            >
                                                Save
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </template>
                            <v-btn
                                :small="true"
                                :depressed="true"
                                :ripple="false"
                                :text="true"
                                color="white"
                                class="bg-white mt-2 group uppercase text-xs font-bold text-red cursor-pointer hover:bg-white"
                                @click.prevent="showButton(practice, 'cancel')"
                            >
                                <fa-icon icon="times" class="text-red mr-1 text-xs"/>
                                <span class="text-red">CANCEL</span>
                            </v-btn>
                        </div>
                    </div>
                    <div class="flex items-start">
                        <v-checkbox
                            :style="(idCreate.length > 0 && idCreate.indexOf(practice.practiceId) < 0) ? `opacity:0;--bg-opacity: 0` : `--bg-opacity: 0`"
                            :disabled="(idCreate.length > 0 && idCreate.indexOf(practice.practiceId) < 0)"
                            :ripple="false"
                            v-model="practice.checkbox"
                            class="transition-all duration-300"
                            @change="showPractice(practice)"
                        ></v-checkbox>
                        <div class="ml-1">
                            <div class="flex items-start">
                                <div class="mt-1 text-xs text-black mb-2">{{practice.name}}</div>

                                <!--TODO: for phase 2, show the Vetoed surveyors -->
<!--                                <v-tooltip bottom>-->
<!--                                    <template v-slot:activator="{ on, attrs }">-->
<!--                                        <fa-icon-->
<!--                                            icon="exclamation-triangle" class="ml-1 mt-1 text-red text-xs"-->
<!--                                            v-bind="attrs"-->
<!--                                            v-on="on"-->
<!--                                        />-->
<!--                                    </template>-->
<!--                                    <span>Vetoed Surveyors: Dr Andrea Smith</span>-->
<!--                                </v-tooltip>-->
                            </div>
                            <div class="grid grid-cols-2 gap-2">
                                <div>
                                    <div class="uppercase text-grey-2 text-xxs font-bold">Survey Due</div>
                                    <div>{{practice.surveyDue}}</div>
                                </div>
                                <div>
                                    <div class="uppercase text-grey-2 text-xxs font-bold">Expiry Date</div>
                                    <div>{{practice.expiryDate}}</div>
                                </div>
                                <div>
                                    <div class="uppercase text-grey-2 text-xxs font-bold">Preferred Days</div>
                                    <div>{{getTagsAsString(practice.preferredDaysOfWeek)}}</div>
                                </div>
                                <div>
                                    <div class="uppercase text-grey-2 text-xxs font-bold">Visit Type</div>
                                    <div>{{getVisitType(practice.svType)}}</div>
                                </div>
                                <div>
                                    <div class="uppercase text-grey-2 text-xxs font-bold">Reason</div>
                                    <div>{{getSurveyVisitReason(practice.svReason)}}</div>
                                </div>
                                <div>
                                    <div class="uppercase text-grey-2 text-xxs font-bold">Surveyor(s)</div>
                                    <div>{{getSurveyorNames(practice.svSurveyorNames)}}</div>
                                </div>
                                <div>
                                    <!-- TODO: Hiding tags for now, need to put back for next phase of SV planning -->
                                    <!--<div class="uppercase text-grey-2 text-xxs font-bold">Tags</div>-->
                                    <!--<div>{{getTagsAsString(practice.tags)}}</div>-->
                                </div>
                            </div>
                            <v-tooltip bottom max-width="300">
                                <template v-slot:activator="{ on, attrs }">
                                    <div
                                        class="flex items-center"
                                        v-if="checkLatLng(practice)"
                                        v-bind="attrs"
                                        v-on="on"
                                    >

                                        <fa-icon icon="times" class="text-xs text-red pr-1" />

                                        <span class="uppercase text-red text-xxs font-bold">Not Geocoded</span>
                                    </div>  
                                </template>
                              <span>This practice's exact location could not be found & is not shown on the map. Ask the QAM to confirm the practice address & update it. If the practice is brand new please wait a day for geocoding to take effect. Otherwise please contact the system admin.</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="practicesLoaded && !practices.length" class="pt-8 w-full text-center text-sm text-grey-1 mt-8">
            No results for the selected filters.
        </div>
        <div v-else class="pt-8 w-full">
            <!-- TODO: think about having the text or not -->
            <Loader class="relative h-16" />
        </div>
    </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import camelcaseKeys from 'camelcase-keys'
import { format, parse } from 'date-fns'
import PracticeResource from '@/services/PracticeResource'
import Loader from '@/assets/loader.svg'
import { goToAdmin } from '@/utils/backendHelpers'
import PracticeListSort from '@/components/survey-visit/partials/PracticeListSort'
import PracticeFiltersMenu from '@/components/survey-visit/partials/PracticeFiltersMenu'
import { getType } from '@/utils/surveyVisit.helpers'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import GpBadge from '@/components/partials/GpBadge'
import { getGoogleMapsAPI } from 'gmap-vue'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import axios from 'axios'
import { mapState } from 'vuex'
import { splitListToChunk } from '@/utils/array.helper'
import { useSurveyVisitStore } from '@/stores/useSurveyVisitStore'
import { storeToRefs, mapState as piniaMapState } from 'pinia'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'VisitPracticeList',
    mixins: [MessageDialog],
    components: { PracticeListSort, PracticeFiltersMenu, Loader, GpBadge },
    props: {
        surveyorCheckedList: {
            type: Array,
            default: () => []
        }
    },
    emits: ['add-practice-to-map'],
    data() {
        return {
            dialog: false,
            practiceId: null,
            visitId: null,
            headers: [
                {
                    text: "NAME",
                    align: 'start',
                    filterable: false,
                    value: 'name'
                },
                {
                    text: "DISTANCE TO PRACTICE",
                    value: 'distance'
                },
                {
                    text: "ACTION",
                    filterable: false
                }
            ],
            radio: "radio-1",
            date: null,
            availDate: null,
            allowedDates: [],
            menu: false,
            leader: "",
            tableData: [],
            confirmationFormTemplate: [],
            templateList: [],
            currentTemplate: "",
            formData: {
                date: "",
                start_time: "09:00",
                confirmation_form_template: null,
                surveyor_kms: []
            },
            disable: false,
            duration: 3000
        }
    },
    methods: {
        async setFormData(surveyorList, checkedList, practice) {
            let data = []
            let visitData = []
            const google = getGoogleMapsAPI()
            const destination = new google.maps.LatLng(practice.lat, practice.lng)

            this.getTimeAndTemplate(practice)
            this.getMatchingPractices(surveyorList, checkedList)
            const originsList = []

            await Promise.all(surveyorList.map(async (i) => {
                const name = i.title.charAt(0) + i.title.slice(1).toLowerCase()

                if(i.id === practice.svLeadSurveyorId) {
                    this.leader = `${name} ${i.firstName} ${i.lastName}`
                }
                Array.from(new Set(checkedList)).forEach((e) => {
                    const surveyor = {}
                    const visitSurveyor = {}
                    if (e === i.id) {
                        const originValue = new google.maps.LatLng(i.lat, i.lng)
                        originsList.push(originValue)

                        surveyor.name = `${name} ${i.firstName} ${i.lastName}`

                        surveyor.isGp = i.isGp
                        visitSurveyor.user = surveyor.id = i.id
                        visitSurveyor.is_lead = practice.svLeadSurveyorId === e
                        data.push(surveyor)
                        visitData.push(visitSurveyor)
                    }
                })
            }))
            const newList = splitListToChunk(originsList)
            let result = await Promise.all(newList.map(async (originsChunk) => {
                return await this.getDistances(originsChunk, [destination])
            }))
            // const result = await this.getDistances(originsList, [destination])
            result = result.flat()
            if (result && result.length) {
                for (let i = 0; i < result.length; i++) {
                    data[i].distance = result?.at(i).elements.at(0).distance.text
                    visitData[i].kilometers = Number(data?.at(i).distance.split(' ').at(0).replace(',', ''))
                }
            }
            this.tableData = data
            this.formData.surveyor_kms = visitData
        },
        async getDistances(origins, destinations) {
            const google = getGoogleMapsAPI()
            const directionsService = new google.maps.DistanceMatrixService()
            const request = {
                origins: origins,
                destinations: destinations,
                travelMode: google.maps.TravelMode.DRIVING,
                unitSystem: google.maps.UnitSystem.METRIC,
                avoidHighways: false,
                avoidTolls: false
            }
            const result = await directionsService.getDistanceMatrix(request)
            return result.rows
        },
        async showDialog(surveyorList, checkedlist, practice) {
            this.dialog = true
            this.disable = false
            this.practiceId = practice.practiceId
            await this.setFormData(surveyorList, checkedlist, practice)
            this.visitId = practice.svId
            this.confirmationFormTemplate = this.staticChoices['survey_visit.confirmation_form_template'] || []
            this.templateList = [...this.confirmationFormTemplate.map(e => e.text)]
        },
        deleteItem(item, surveyorList) {
            const list = []
            const index = this.tableData.indexOf(item)
            this.tableData.splice(index, 1)
            this.tableData.map(i => {
                list.push(i.id)
            })
            this.getMatchingPractices(surveyorList, list)
            this.formData.surveyor_kms.splice(index, 1)
            if(!this.tableData.length) {
                this.disable = true
            }
        },
        setLeader(e) {
            this.leader = e
            let id
            this.tableData.map(i => {
                if(e === i.name) {
                    id = i.id
                }
            })
            this.formData.surveyor_kms.map(i => {
                i.is_lead = id === i.user ? true : false
            })
        },
        getTimeAndTemplate(practice) {
            if(practice.svStartTime) this.formData.start_time = practice.svStartTime
            if(practice.svConfirmationFormTemplate) {
                const id = Number(practice.svConfirmationFormTemplate.slice(0, 1))
                this.confirmationFormTemplate.map(e => {
                    if(e.value === id) {
                        this.formData.confirmation_form_template = e.value
                        this.currentTemplate = e.text
                    }
                })
            }
        },
        setConfirmationTemplate(e) {
            this.currentTemplate = e
            this.confirmationFormTemplate.map(i => {
                if(i.text === e) {
                    this.formData.confirmation_form_template = i.value
                }
            })
        },
        setStartTime(e) {
            this.formData.start_time = e
        },
        getMatchingPractices(list, checkedList) {
            const daysList = []
            const result = []
            list.map(i => {
                checkedList.map(e => {
                    if(e === i.id) {
                        daysList.push(i.matchingPractices[this.practiceId])
                    }
                })
            })
            const flatArr = daysList.flat()
            flatArr.map(e => {
                let count = 0
                for(let i = 0; i < flatArr.length; i++) {
                    if(e === flatArr[i]) {
                        count++
                    }
                }
                if(count === daysList.length) {
                    result.push(e)
                }
            })
            this.allowedDates = Array.from(new Set(result)).sort((a, b) => new Date(a) - new Date(b))
        },
        validDates(val) {
            return val >= new Date().toISOString().substr(0, 10)
        },
        setDate(e) {
            this.menu = false
            this.date = e
        },
        setAvailDate(e) {
            this.availDate = e
        },
        resetFormData() {
            this.leader = null
            this.radio = "radio-1"
            this.date = null
            this.availDate = null
            this.currentTemplate = ""
            this.tableData = []
            this.templateList = []
            this.formData = {
                date: "",
                start_time: "09:00",
                confirmation_form_template: null,
                surveyor_kms: []
            }
            this.idCreate = []
            this.dialog = false
        },
        submitFormData() {
            this.validate()
            this.formData.date = (this.radio === "radio-1") ? this.availDate : this.date
            if(this.leader && this.tableData.length && this.formData.date && this.formData.confirmation_form_template) {
                axios.post(`web.surveyvisit/${this.visitId}/plan/`, this.formData)
                    .then(response => {
                        this.message = 'Data have been saved'
                        this.type = 'success'
                        this.getPractices(this.computedPracticeFilter)
                        this.practiceId = null
                        this.idCreate = []
                    })
                    .catch(error => {
                        this.message = `Error: Request failed with status code ${error.response.status}`
                        this.type = 'error'
                    })
                    .finally(() => {
                        this.resetFormData()
                        this.showMessage({duration: this.duration})
                        this.dialog = false
                    })
            }
        },
        validate() {
            if((this.radio === "radio-1") && (this.availDate === null)) {
                this.$refs.availDate[0].classList.value = ["absolute bottom-0 text-xs text-red"]
            } else {
                this.$refs.availDate[0].classList.value = "hidden"
            }
    
            if((this.radio === "radio-2") && (this.date === null)) {
                this.$refs.date[0].classList.value = ["absolute bottom-0 text-xs text-red"]
            } else {
                this.$refs.date[0].classList.value = "hidden"
            }

            if(!this.leader) {
                this.$refs.leader[0].classList.value = ["absolute bottom-0 text-xs text-red"]
            } else {
                this.$refs.leader[0].classList.value = "hidden"
            }
            
            if(!this.tableData.length) {
                this.$refs.table[0].classList.value = ["absolute bottom-0 text-xs text-red w-full text-center"]
            } else {
                this.$refs.table[0].classList.value = "hidden"
            }

            if(!this.formData.confirmation_form_template) {
                this.$refs.template[0].classList.value = ["absolute bottom-1 text-xs text-red"]
            } else {
                this.$refs.template[0].classList.value = "hidden"
            }
        },
        checkLatLng(practice) {
            return (!practice.lat || !practice.lng) ? true : false
        }
    },
    computed: {
        ...mapState({
            staticChoices: state => state.app.staticChoices
        }),
        ...piniaMapState(useSurveyVisitStore, ['computedPracticeFilter']),
    },
    setup(_, { emit }) {
        const idCreate = ref([])
        const items = ref([])
        const checkbox = ref(true)
        const practices = ref([])
        const surveyorList = ref([])
        const isCheckAll = ref(true)
        const surveyVisitStore = useSurveyVisitStore()
        const { computedPracticeFilter: practiceFilter } = storeToRefs(surveyVisitStore)
        const { updatePractices, updateSurveyorsAndList, updateSurveyorsList, updatePracticeList, updateFirstMapVisit } = surveyVisitStore
        const practicesLoaded = ref(true)
        const sortDirection = ref('')
        const practiceIds = ref([])
        // we need this because of the combined call to get the practice and surveyors
        const skipSetPractice = ref(false)
        const { surveyVisitReasons } = useGetters(['surveyVisitReasons'])

        const practiceResource = new PracticeResource()

        //#region functions
        const goToCreateVisit = (practice) => {
            // want to go to url like this:
            // https://stg-connect21-dj.codesource.com.au/admin-mnqa/web/accreditation/1368/change/
            //TODO: change the `practice.id` value below to the accreditation id when it's available
            goToAdmin(`admin-mnqa/web/accreditation/${practice.accredId}/change/`, true)
        }
        const getGpType = (gpType) => {
            return gpType ? 'gp' : 'non-gp'
        }

        const setPractices = (practiceList) => {
            updatePracticeList([])
            const converted = camelcaseKeys(practiceList || [], { deep: true })
            updatePracticeList([...converted])
            practices.value = [...converted]
            // TODO: this is just for testing, need to remove when we have the actual data
            sortPractices()
            for(const practice of practices.value) {
                const svDue = practice?.svDue
                const expiry = practice?.expiryDate
                practice.surveyDue = svDue ? format(new Date(svDue), 'MMM yyyy') : ''
                practice.expiryDate = expiry ? format(new Date(expiry), 'dd MMM yyyy') : ''
                //TODO: need to change the below preferred days to what's enterred from the practice, setting to blank for now
                practice.tags = []
                practice.checkbox = true
                updatePractices(practice)
                practiceIds.value.push(practice.practiceId)
            }
        }
        
        const setSurveyors = (surveyorList) => {
            if (!surveyorList?.length) updateSurveyorsList([])
            const converted = camelcaseKeys(surveyorList || [], { deep: true })
            const surveyors = [...converted]
            // TODO: this is just for testing, need to remove when we have the actual data

            updateSurveyorsAndList(surveyors)
        }

        const sortPractices = () => {
            if (!sortDirection.value) return

            // minus sign in text corresponds to desc order
            const idxOfMinus = sortDirection.value.indexOf('-')
            // using a temp array so we can preserve reactivity and force an update once we have finished sorting
            // this is because of how ref's work in Vue
            let tempPractices = [...(practices.value || [])]
            tempPractices.sort((a, b) => {
                const leftDate = parse(a.svDue, 'yyyy-MM-dd', new Date())
                const rightDate = parse(b.svDue, 'yyyy-MM-dd', new Date())
                if (idxOfMinus > -1) {
                    return leftDate < rightDate ? -1 : leftDate > rightDate ? 1 : 0
                }
                return leftDate > rightDate ? -1 : rightDate < leftDate ? 1 : 0
            })
            practices.value = [...tempPractices]
        }

        const getTagsAsString = (list) => {
            const result = []
            list?.map(e => {
                result.push(e.slice(0, 3))
            })
            return result.join(', ') || '-'
        }

        const getSurveyorNames = (name) => {
            return name || '-'
        }

        const showPractice = async (practice) => {
            // emit('add-practice-to-map', practice)
            // we remove the practice from the list of Ids so we can filter the surveyors
            if (!practice.checkbox) {
                const idx = practiceIds.value.findIndex(p => p === practice.practiceId)
                if (idx > 0) practiceIds.value.splice(idx, 1)
            } else {
                practiceIds.value.push(practice.practiceId)
            }
            skipSetPractice.value = true

            await getPractices(practiceFilter.value)
            updatePractices(practice)
        }

        const getPractices = async (newPracticeFilter) => {
            const filters = {}
            // need to clear the surveyors list from pinia first
            updateSurveyorsList([])
            const practiceFilterType = surveyVisitStore?.computedPracticeFilterType
            if (practiceFilterType) filters[practiceFilterType] = newPracticeFilter[practiceFilterType]
            if (practiceIds.value.length) filters.practiceIds = practiceIds.value
            const response = await practiceResource.getPracticeAndSurveyors(
                newPracticeFilter.dateRange?.at(0),
                newPracticeFilter.dateRange?.at(1),
                filters
            )
            const { practices, surveyors } = response
            if (!skipSetPractice.value) setPractices(practices)
            // setPractices(practices)
            surveyorList.value = surveyors
            setSurveyors(surveyors)

            skipSetPractice.value = false
            updateFirstMapVisit(false)
        }

        const getVisitType = (visitType) => {
            // need to pass the object as getType expects it, the function checks for surveyVisit and then type
            return getType({surveyVisit: { type: visitType }})
        }

        const getSurveyVisitReason = (svReason) => {
            return surveyVisitReasons.value.find(svr => svr.value === svReason)?.text || '-'
        }

        const showButton = (practice, typeButton) => {
            if(typeButton === 'create')
                idCreate.value.push(practice.practiceId)
            else if(typeButton === 'cancel'){
                idCreate.value.splice(idCreate.value.indexOf(practice.practiceId), 1)
            }
        }
        //#endregion
        const checkAll = () => {
            isCheckAll.value = !isCheckAll.value
        }
        
        watch(() => isCheckAll.value, async (value) => {
            for(const practice of practices.value){
                if(value && !practice.checkbox ){
                    practice.checkbox = value
                    practiceIds.value.push(practice.practiceId)
                    practiceIds.value = Array.from(new Set(practiceIds.value))
                }
                if(!value && practice.checkbox ){
                    practice.checkbox = value
                    const idx = practiceIds.value.findIndex(p => p === practice.practiceId)
                    if (idx > 0) practiceIds.value.splice(idx, 1)
                }
                updatePractices(practice) 
            }
        }, { deep: true })

        watch(() => idCreate.value, (value) => {
            emit('eventShowCheck', value)
        })

        //#region watches
        watch(practiceFilter, async (newPracticeFilter) => {
            if (newPracticeFilter?.dateRange?.length < 2 || skipSetPractice.value) return

            // we need to remove the practices from the map first
            practicesLoaded.value = false
            practiceIds.value = []
            await getPractices(newPracticeFilter)

            practicesLoaded.value = true
        }, { deep: true })
        //#endregion

        return {
            isCheckAll, practices, checkbox, items, sortDirection, practicesLoaded, idCreate, surveyorList,
            surveyVisitStore, sortPractices, showPractice, getTagsAsString, goToCreateVisit, getVisitType,
            getSurveyVisitReason, getSurveyorNames, showButton, checkAll, getGpType, getPractices
        }
    },
}
</script>
