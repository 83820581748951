import { defineStore } from 'pinia'
import { computed, ref } from '@vue/composition-api'

export const useSurveyVisitStore = defineStore('surveyVisit', () => {
    const _practiceList = ref([])
    const _surveyors = ref([])
    const _surveyorsList = ref([])
    const _surveyorFilter = ref({})
    const _practiceFilter = ref({})
    const practiceFilterType = ref('')
    const firstMapVisit = ref(true)
    
    const computedPracticeList = computed(() => _practiceList.value)
    const computedSurveyors = computed(() => _surveyors.value)
    const computedSurveyorsList = computed(() => _surveyorsList.value)
    const computedSurveyorFilter = computed(() => _surveyorFilter.value)
    const computedPracticeFilter = computed(() => _practiceFilter.value)
    const computedPracticeFilterType = computed(() => practiceFilterType.value)
    const computedFirstMapVisit = computed(() => firstMapVisit.value)
    
    // console.log('creating the practice filter type thingy', computedPracticeFilterType)
    
    const updatePracticeList = (newList) => {
        _practiceList.value = [...(newList || [])]
    }
    const updatePractices = (practice) => {
        let practices = [...(_practiceList.value || [])]
        const idx = practices.findIndex(pl => pl.practiceId === practice.practiceId)
        if (idx === -1 && practice.checkbox) practices.push(practice)
        else if(idx > -1 && !practice.checkbox) practices = [...practices.slice(0, idx), ...practices.slice(idx + 1)]
        
        _practiceList.value = [...practices]
    }
    const updateSurveyors = (surveyor) => {
        // this.surveyors = surveyors
        let surveyors = [...(_surveyors.value || [])]
        const idx = surveyors.findIndex(pl => pl.id === surveyor.id)
        if (idx === -1) surveyors.push(surveyor)
        else if(idx > -1) surveyors = [...surveyors.slice(0, idx), ...surveyors.slice(idx + 1)]
        
        _surveyors.value = surveyors
        updateSurveyorsList(surveyors)
    }
    const updateSurveyorsAndList = (surveyors) => {
        _surveyors.value = surveyors
        updateSurveyorsList(surveyors)
    }
    const updateSurveyorsList = (surveyors) => {
        _surveyorsList.value = surveyors
    }
    const updateSurveyorFilter = (filter) => {
        _surveyorFilter.value = {...filter}
    }
    const updatePracticeFilter = (filter) => {
        _practiceFilter.value = {...filter}
    }
    const updatePracticeFilterType = (filterType) => {
        if (typeof filterType !== 'string') throw new Error('filter type must be of type string')
        // _practiceFilterType.value = filterType
        practiceFilterType.value = filterType
    }
    
    const updateFirstMapVisit = (value) => {
        if (typeof value !== 'boolean') throw new Error('first map visit must be of type boolean')
        // _practiceFilterType.value = value
        firstMapVisit.value = value
    }
    
    return {
        computedPracticeList, computedSurveyors, computedSurveyorsList, computedSurveyorFilter,
        computedPracticeFilter, computedPracticeFilterType, computedFirstMapVisit, updatePracticeList,
        updatePractices, updateSurveyors, updateSurveyorsAndList, updateSurveyorsList,
        updateSurveyorFilter, updatePracticeFilter, updatePracticeFilterType, updateFirstMapVisit
    }
})
