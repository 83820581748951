<template>
    <v-menu
        bottom left offset-y
        transition="slide-y-transition"
        :close-on-content-click="false"
        :nudge-width="200"
        :value="showFilter"
    >
        <template v-slot:activator="{ on, attrs }">
            <fa-icon
                icon="filter"
                class="text-black text-xs px-3"
                v-bind="attrs"
                v-on="on"
                @click="updateShowFilter"
            />
        </template>
        <div class="p-3 text-xs bg-white-pure">
            <div class="grid grid-cols-3 gap-2 mb-4">
                <div>
                    <div class="uppercase text-grey-2 text-xxs font-bold">Gp/Non-gp</div>
                    <div>
                        <v-select
                            outlined dense hide-details
                            v-model="filterRef.gpType"
                            :items="gpOptions"
                        ></v-select>
                    </div>
                </div>
                <div>
                    <div class="uppercase text-grey-2 text-xxs font-bold">Employment</div>
                    <div>
                        <v-select
                            outlined dense hide-details
                            v-model="filterRef.employment"
                            :items="employmentOptions"
                        ></v-select>
                    </div>
                </div>
                <div>
                    <div class="uppercase text-grey-2 text-xxs font-bold">Cost rating</div>
                    <div>
                        <v-select
                            outlined dense hide-details
                            v-model="filterRef.costRating"
                            :items="costRatingsRef"
                        ></v-select>
                    </div>
                </div>
            </div>

            <div class="uppercase text-grey-2 text-xxs font-bold">Availability</div>
            <div class="flex items-center">
                <v-checkbox
                    v-for="(day, idx) in availabilityDays"
                    :key="`${day}-${idx}`"
                    multiple
                    :ripple="false"
                    class="mt-0 mr-3"
                    :label="day.text"
                    :value="day.value"
                    v-model="filterRef.availability"
                ></v-checkbox>
            </div>

            <!-- TODO: need to add back in phase 2 -->
            <!--
            <div class="uppercase text-grey-2 text-xxs font-bold">Preferred days</div>
            <div>
                <v-checkbox
                    :ripple="false"
                    class="mt-0"
                    v-model="filterRef.preferredDays"
                    :label="`Only show surveyors matching practice's preferred days`"
                ></v-checkbox>
            </div>
            -->
            <!-- TODO: add back in phase 2 when we have cost rating filters
            <div class="uppercase text-grey-2 text-xxs font-bold">Location</div>
            <v-radio-group class="mt-0" v-model="location">
                <div class="flex items-center mb-2">
                    <v-radio
                        :ripple="false"
                        :key="`location-1`"
                        value="1"
                    ></v-radio>
                    <div class="ml-2 w-72">
                        <v-select
                            single dense flat outlined hide-details
                            ripple="false"
                            :items="items"
                            label="Rural"
                            v-model="filterRef.location"
                            @focus="changeLocation('1')"
                        ></v-select>
                    </div>
                </div>
                <div class="flex items-center mb-2">
                    <v-radio
                        :ripple="false"
                        :key="`location-2`"
                        value="2"
                    ></v-radio>
                    <div class="ml-2 w-72">
                        <v-text-field
                            single dense flat outlined hide-details
                            label="Enter postcode"
                            v-model="filterRef.postcode"
                            @focus="changeLocation('2')"
                        ></v-text-field>
                    </div>
                </div>
                <div class="flex items-center">
                    <v-radio
                        :ripple="false"
                        :key="`location-3`"
                        value="3"
                    ></v-radio>
                    <div class="ml-2 w-1/2">
                        <v-text-field
                            single dense flat outlined hide-details
                            label="Distance based on coordinates"
                            v-model="filterRef.distance"
                            @focus="changeLocation('3')"
                        ></v-text-field>
                    </div>
                </div>
            </v-radio-group>
            -->
            <div class="flex items-center">
                <v-btn
                    depressed outlined text
                    :small="true"
                    :ripple="false"
                    color="white"
                    class="border border-grey-4 mr-2"
                    @click="resetFilters"
                >Clear Filters</v-btn>
                <v-btn
                    :small="true"
                    :ripple="false"
                    color="primary"
                    depressed
                    @click="updateFilters"
                >Update</v-btn>
            </div>
        </div>
    </v-menu>
</template>

<script>
import { ref, watchEffect, computed, onMounted } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'

const { useGetters } = createNamespacedHelpers('')

export default {
    name: 'SurveyorFilter',
    props: {
        value: {
            type: Object,
            default: () => ({
                gpType: '',
                employment: '',
                availability: [],
                preferredDays: true
            })
        }
    },
    setup(props, { emit }) {
        const items = ref([])
        const availabilityDays = [
            {text: 'Mon', value: 'monday'},
            {text: 'Tue', value: 'tuesday'},
            {text: 'Wed', value: 'wednesday'},
            {text: 'Thu', value: 'thursday'},
            {text: 'Fri', value: 'friday'}
        ]
        const gpOptions = [{value: 'GP', text: 'GP'}, {value: 'NON_GP', text: 'Non-GP'}, {value: 'ANY', text: 'Any'}]
        const employmentOptions = [{value: 'FULL_TIME', text: 'Full time'}, {value: 'CONTRACT', text: 'Contract'}, {value: 'ANY', text: 'Any'}]
        const filterRef = ref(props?.value)
        const availability = ref([])
        const location = ref('')
        const showFilter = ref(null)
        const { costRatings } = useGetters(['costRatings'])
        const costRatingsRef = ref([])
        //just need to have an 'any' filter as well

        //#region functions
        const changeLocation = (value) => {
            location.value = value
        }

        const closeFilter = () => {
            showFilter.value = false
        }

        const resetFilters = () => {
            filterRef.value = {
                gpType: '',
                employment: '',
                availability: [],
                preferredDays: true,
                distance: '',
                postcode: '',
                location: ''
            }
            emit('input', {...filterRef.value})
            closeFilter()
        }

        const updateFilters = () => {
            emit('input', {...filterRef.value})
            closeFilter()
        }

        const updateShowFilter = () => {
            showFilter.value = true
        }
        //#endregion

        //#region computed properties
        const filter = computed(() => filterRef.value)
        //#endregion

        //#region watches
        // watchEffect(() => emit('input', filterRef.value))
        watchEffect(() => costRatingsRef.value = [{value: 'ANY', text: 'Any'}, ...costRatings.value])
        //#endregion

        //#region life cycle hooks
        onMounted(() => {
            filterRef.value.gpType = 'ANY'
            filterRef.value.employment = 'ANY'
            filterRef.value.costRating = 'ANY'
        })
        //#endregion

        return {
            items,
            filter,
            filterRef,
            availabilityDays,
            gpOptions,
            availability,
            location,
            showFilter,
            employmentOptions,
            costRatingsRef,
            changeLocation,
            closeFilter,
            resetFilters,
            updateFilters,
            updateShowFilter
        }
    }
}
</script>
