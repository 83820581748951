<template>
    <div class="grid grid-cols-10">
        <VisitPracticeList
            class="col-span-5 border border-grey-4 border-l-0 border-t-0 border-b-0"
            :surveyorCheckedList="surveyorCheckedList"
            @eventShowCheck="eventShowCheck"
        />
        <VisitSurveyorList
            class="col-span-5 border border-grey-4 border-l-0 border-t-0 border-b-0"
            :idCreate="idCreate"
            @eventCheckSurveyor="eventCheckSurveyor"
        />
    </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import VisitPracticeList from '@/components/survey-visit/partials/VisitPracticeList'
import VisitSurveyorList from '@/components/survey-visit/partials/VisitSurveyorList'


export default {
    name: 'SurveyVisitFilters',
    components: {VisitPracticeList, VisitSurveyorList},
    data: () => ({
        idCreate: [],
        surveyorCheckedList: [],
    }),
    methods: {
        eventShowCheck(value){
            this.idCreate = value
        },
        eventCheckSurveyor(value){
            this.surveyorCheckedList = value
        }
    },
    setup() {
        const title = ref('filters')

        return {
            title
        }
    }
}
</script>
