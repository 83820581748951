<template>
    <div
        :class="bgClass"
        class="text-xs uppercase p-1 rounded font-bold"
    >
        <span
            :class="statusClass"
        >{{ statusName }}</span>
    </div>
</template>

<script>
export default {
    name: 'GpBadge',
    props: {
        gpType: {
            type: String,
            default: ''
        },
        //TODO: remove if not used
        gpTypeList: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        statusName() {
            if (this.gpTypeList.length) {
                const status = this.gpTypeList.find(sl => sl.value.toLowerCase() === this.gpType.toLowerCase())
                return status !== undefined && status !== null ? status.label : ''
            } else {
                return this.gpType
            }
        },
        bgClass() {
            if (this.statusName.toLowerCase() === 'gp') {
                return ['bg-purple-light']
            } else if (this.statusName.toLowerCase() === 'non-gp') {
                return ['bg-orange-light']
            } else {
                return []
            }
        },
        statusClass() {
            if (this.statusName.toLowerCase() === 'gp') {
                return ['text-purple']
            } else if (this.statusName.toLowerCase() === 'non-gp') {
                return ['text-orange']
            } else {
                return []
            }
        }
    }
}
</script>
