import gql from 'graphql-tag'

const LIST_SURVEYORS = gql `
    query listSurveyors {
        users: user(
            where: {
                is_surveyor: {_eq: true}
            }
        ) {
            id
            first_name
            last_name
            availability
            unavailable_until_further_notice
            address {
                id
                lat
                lng
            }
            unavailability(where: {archived: {_eq: false}}) {
                id
                date_from
                date_to
                description
                archived
            }
        }
    }
`

const GET_SURVEYOR_RATE_CLASSIFICATIONS = gql`
    query getSurveyorRateClassification {
        rateClassifications: surveyor_rate_classification {
            id
            hourly_rate
            description
        }
    }
`

export {
    LIST_SURVEYORS,
    GET_SURVEYOR_RATE_CLASSIFICATIONS
}
