import { DEFAULT_BACKEND_ADMIN_URL, DJANGO_BASE_URL } from '@/config/constants'

const goToAdmin = (backendAdminUrl, openInNewTab = false) => {
    const url = `${DJANGO_BASE_URL}/${backendAdminUrl || DEFAULT_BACKEND_ADMIN_URL}`
    goToBackendUrl(url, openInNewTab)
    // console.log('go to backend admin url')
}

const goToBackendUrl = (url, openInNewTab) => {
    console.log('url to go to', url)
    if (openInNewTab) {
        window.open(url, '_blank')
    } else {
        window.location = url
    }
}

export {
    goToBackendUrl,
    goToAdmin,
}
