<template>
    <div class="w-full h-full relative">
        <!--Practice-->
        <div
            v-if="selectedPractice.name"
            id="practicePinText"
            class="selectedPinText px-2 text-xs absolute top-0 left-0 text-white bg-black"
        >
            Selected practice <strong>{{selectedPractice.name}}</strong>.
        </div>
        <div
            v-if="selectedPractice.name"
            id="closestSurveyorsText"
            class="selectedPinText px-2 text-xs absolute top-0 left-0 text-black bg-white"
        >
            The closest 2 surveyors are <strong class="text-light-blue">{{ closestSurveyorName }} (Distance to practice: {{ gpSurveyorDistance }} | Travel time: {{ gpSurveyorDuration }})</strong>
            <div>& <strong class="text-orange">{{ closestSurveyorNonGpName }} (Distance to practice: {{ nonGpSurveyorDistance }} | Travel time: {{ nonGpSurveyorDuration }})</strong> .</div>
        </div>
        <!--Surveyors-->
        <!--If they select a surveyor (non-gp) please use 'bg-orange', and if they click surveyor (gp) please use 'bg-green'-->
        <div
            v-if="selectedSurveyor.name"
            id="surveyorPinText"
            class="selectedPinText px-2 text-xs absolute text-white"
            :class="getSurveyorBgClass(selectedSurveyor)"
        >
            <div class="w-full">Selected surveyor <strong>{{selectedSurveyor.name}}</strong>.</div>
            <div class="flex-col items-center w-full" v-if="havePractice">
                <div v-if="surveyorDistance" class="mr-3"><strong>Distance to selected practice</strong>: {{surveyorDistance}}</div>
                <div v-if="surveyorDuration"><strong>Travel time</strong>: {{surveyorDuration}} </div>
            </div>
        </div>
        <div
            id="reloadMap"
            class="selectedPinText p-2 text-xs absolute text-white bg-black top-0 right-0 cursor-pointer"
            :class="{'disabled': !havePractice && !selectedSurveyor.name }"
            @click="reloadMap"
        >
            <div class="w-full">
                <fa-icon icon="sync-alt" class="mr-2" />Reset map
            </div>
        </div>
        <GmapMap
            ref="svMap"
            class="w-full h-full"
            :center="center"
            :zoom="zoom"
            :map-type-id="mapTypeId"
            :options="options"
        >
            <GmapMarker
                v-for="(m, index) in practiceMarkers"
                :key="`practice-${index}`"
                :position="m.position"
                :clickable="m.clickable"
                :draggable="m.draggable"
                :visible="m.visible"
                :icon="m.icon"
                @click="getClosestSurveyors(m, surveyorMarkers)"
            />
            <GmapMarker
                v-for="(m, index) in surveyorMarkers"
                :key="`surveyors-${index}`"
                :position="m.position"
                :clickable="m.clickable"
                :draggable="m.draggable"
                :visible="m.visible"
                :icon="m.icon"
                @click="surveyorClickHandler(m)"
            />
        </GmapMap>
    </div>
</template>

<script>
import { computed, ref, watch, nextTick } from '@vue/composition-api'
import DirectionsRenderer from '@/components/google-maps/DirectionsRenderer'
import { useSurveyMap } from '@/composables/surveyMap'
import {
    CLICKED_SCALE,
    DEFAULT_OPACITY, DEFAULT_SCALE,
    GP_COLOUR,
    INACTIVE_OPACITY,
    NON_GP_COLOUR,
    PRACTICE_COLOUR,
    STYLES_MAP
} from '@/config/constants'
import { useNameHelpers } from '@/composables/nameHelpers'
import { useSurveyVisitStore } from '@/stores/useSurveyVisitStore'
import { storeToRefs } from 'pinia'

export default {
    name: 'SurveyVisitMap',
    components: {DirectionsRenderer},
    setup() {
        const surveyVisitStore = useSurveyVisitStore()
        const title = ref('Map')
        const mapTypeId = ref('roadmap')
        const zoom = ref(15)
        const selectedPin = ref(null)

        // refs to each marker in the map
        const startLocation = ref({})
        const endLocation = ref({})
        const {
            svMap, center, practiceMarkers, surveyorMarkers,
            practiceLatLngList, surveyorLatLngList, closestSurveyor,
            closestSurveyorNonGp, selectedPractice, selectedSurveyor,
            selectedSurveyorDistance, changeCenter, changeZoom,
            clearPracticeMarkers, clearSurveyorMarkers, createNewMarker,
            getClosestSurveyors, clearClosestMarkers, setClickedSurveyor,
            getSurveyorBgClass, reloadMap
        } = useSurveyMap()
        const { getFullName } = useNameHelpers()

        const options = ref({
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: true,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUI: false,
            styles: STYLES_MAP
        })

        //#region functions
        const surveyorClickHandler = (marker) => {
            for (const m of surveyorMarkers.value) {
                const surveyor = m.csSurveyor

                // if (m.icon.opacity === INACTIVE_OPACITY) continue
                const scale = marker.csSurveyor?.id === surveyor?.id ? CLICKED_SCALE : DEFAULT_SCALE
                // const title = marker.csPractice?.id === practice.id ? practice.name : ''
                const icon = m.icon
                icon.scale = scale
                m.setOptions({'icon': {...icon}})
            }

            setClickedSurveyor(marker?.csSurveyor)
        }

        const drawSurveyorMarkers = (surveyorsList) => {
            // clear the map first
            clearSurveyorMarkers()
            surveyorMarkers.value = []
            surveyorLatLngList.value = []
            //set default opacity if we have a closest gp and non-gp
            const defaultOpacity = closestSurveyor.value || closestSurveyorNonGp.value ? INACTIVE_OPACITY : DEFAULT_OPACITY
            for (const surveyor of surveyorsList) {
                const { lat, lng } = surveyor
                let opacity = defaultOpacity
                if(!lat || !lng) continue

                const closestSurveyors = [closestSurveyor.value?.id, closestSurveyorNonGp.value?.id]
                const haveClosestSurveyor = closestSurveyors.indexOf(surveyor.id) > -1
                if (haveClosestSurveyor) opacity = DEFAULT_OPACITY

                const colour = surveyor.isGp ? GP_COLOUR : NON_GP_COLOUR
                surveyor.name = getFullName(surveyor)
                const { marker, latLng } = createNewMarker(
                    { lat, lng },
                    true,
                    opacity,
                    colour,
                    false,
                    surveyor.name,
                    haveClosestSurveyor //only add the label if we have a closest surveyor
                )
                // now we make the InfoWindow
                let distance = undefined
                if (closestSurveyors.indexOf(surveyor.id) > -1) {
                    distance = closestSurveyor.value?.id === surveyor.id ?
                        closestSurveyor.value.distanceAndDuration : closestSurveyorNonGp.value.distanceAndDuration
                }
                // const infoWindow = createNewInfoWindow(surveyor.name, distance)
                // need to put this here so we can show it in the header
                surveyor.distance = distance
                // need to add an onclick for the marker and infoWindow.open
                marker.addListener('click', () => {
                    surveyorClickHandler(marker)
                })
                marker.csSurveyor = surveyor
                surveyorMarkers.value.push(marker)
                surveyorLatLngList.value.push(latLng)
            }
            // set center to the last surveyor in the list
            if (surveyorMarkers.value.length) changeZoom([...practiceLatLngList.value, ...surveyorLatLngList.value])
        }

        const drawPracticeMarkers = (practices) => {
            practiceMarkers.value = []
            practiceLatLngList.value = []
            for (const practice of practices) {
                const { lat, lng } = practice
                if(!lat || !lng) continue

                const { marker, latLng } = createNewMarker(
                    {lat, lng},
                    true,
                    DEFAULT_OPACITY,
                    PRACTICE_COLOUR,
                    false,
                    practice.name,
                    false
                )
                marker.csPractice = practice
                practiceLatLngList.value.push(latLng)
                practiceMarkers.value.push(marker)
            }
            // set center to the last practice in the list
            if (practiceMarkers.value.length) changeZoom([...practiceLatLngList.value, ...surveyorLatLngList.value])
        }

        //#endregion

        //#region computed props
        const {computedPracticeList: practiceList, computedSurveyorsList: surveyors, computedPracticeFilter: practiceFilter } = storeToRefs(surveyVisitStore)
        const havePractice = computed(() => Object.keys(selectedPractice.value || {}).length > 0)
        const surveyorDistance = computed(() => selectedSurveyor.value?.distance?.distance?.text || selectedSurveyorDistance.value?.at(0)?.distance?.text || '')
        const surveyorDuration = computed(() => selectedSurveyor.value?.distance?.duration?.text || selectedSurveyorDistance.value?.at(0)?.duration?.text || '')

        const gpSurveyorDistance = computed(() => closestSurveyor.value?.distance?.distance?.text)
        const gpSurveyorDuration = computed(() => closestSurveyor.value?.distance?.duration?.text)
        const nonGpSurveyorDistance = computed(() => closestSurveyorNonGp.value?.distance?.distance?.text)
        const nonGpSurveyorDuration = computed(() => closestSurveyorNonGp.value?.distance?.duration?.text)

        const closestSurveyorName = computed(() => closestSurveyor.value?.name || '')
        const closestSurveyorNonGpName = computed(() => closestSurveyorNonGp.value?.name || '')
        //#endregion

        //#region watches
        watch(practiceList, (newValue) => {
            // clear the map first
            clearPracticeMarkers()
            nextTick(() => {
                drawPracticeMarkers(newValue)
            })
        }, { deep: true })

        watch(surveyors, (newValue) => {
            clearClosestMarkers()
            drawSurveyorMarkers(newValue)
        })

        watch([closestSurveyor, closestSurveyorNonGp], () => {
            if (closestSurveyor.value || closestSurveyorNonGp.value) drawSurveyorMarkers(surveyors.value)
        })

        watch(practiceFilter, () => {
            clearPracticeMarkers()
            clearSurveyorMarkers()
            selectedSurveyor.value = {}
            selectedPractice.value = {}
        })

        return {
            zoom,
            title,
            center,
            options,
            practiceMarkers,
            mapTypeId,
            startLocation,
            endLocation,
            svMap,
            surveyorMarkers,
            selectedPin,
            // selectedMarker,
            selectedPractice,
            selectedSurveyor,
            surveyorDistance,
            surveyorDuration,
            closestSurveyor,
            closestSurveyorNonGp,
            gpSurveyorDistance,
            gpSurveyorDuration,
            nonGpSurveyorDistance,
            nonGpSurveyorDuration,
            closestSurveyorName,
            closestSurveyorNonGpName,
            havePractice,
            changeCenter,
            changeZoom,
            getClosestSurveyors,
            surveyorClickHandler,
            getSurveyorBgClass,
            reloadMap
        }
    }
}
</script>
